import * as amplitude from "@amplitude/analytics-browser";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Fetch from "i18next-fetch-backend";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";

import { ENTRY_META_CONFIG } from "~/constants/global.ts";
import { fallbackLng, supportedLngs } from "~/i18n.config";
import { MuiProvider } from "~/mui-provider.tsx";
import { getEnvVariable } from "~shared/get-env-variable.ts";
Sentry.init({
	...ENTRY_META_CONFIG,
	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1,
	tracePropagationTargets: ["localhost", /^https:\/\/*.smart-cube-360\.de/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
});

amplitude.init(getEnvVariable("AMPLITUDE_TOKEN"), {
	instanceName: getEnvVariable("DEPLOY_ENV"),
	logLevel:
		getEnvVariable("DEPLOY_ENV") === "prod"
			? amplitude.Types.LogLevel.Warn
			: amplitude.Types.LogLevel.None,
	defaultTracking: {
		attribution: false,
		pageViews: false,
		sessions: true,
		formInteractions: true,
		fileDownloads: false,
	},
});

async function hydrate() {
	const commitHash = getEnvVariable("LATEST_COMMIT_SHA");
	await i18next
		.use(initReactI18next)
		.use(Fetch)
		.use(LanguageDetector)
		.init({
			fallbackLng,
			supportedLngs,
			detection: {
				order: ["htmlTag"],
				caches: [],
			},
			backend: {
				// commitHash is used to bust the cache when the app is deployed
				loadPath: `/locales?lng={{lng}}&version=${commitHash}`,
			},
		});

	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<MuiProvider>
					<I18nextProvider i18n={i18next}>
						<RemixBrowser />
					</I18nextProvider>
				</MuiProvider>
			</StrictMode>,
		);
	});
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (globalThis.requestIdleCallback) {
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	globalThis.requestIdleCallback(hydrate);
} else {
	// Safari doesn't support requestIdleCallback
	// https://caniuse.com/requestidlecallback
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	globalThis.setTimeout(hydrate, 1);
}
